export const static_translation = {
    "bar_chart_general": "Geral",
    "bar_chart_students": "Seus alunos",
    "dashboard_audio_player_ditado": "Pausado",
    "dashboard_audio_player_original": "Original",
    "dashboard_become_teacher": "Efetivar Perfil Professor",
    "dashboard_create_new_password_check1_text": "Letra maiúscula",
    "dashboard_create_new_password_check2_text": "Número",
    "dashboard_create_new_password_check3_text": "Pelo menos 6 caracteres",
    "dashboard_create_new_password_check4_text": "Caractere especial",
    "dashboard_create_new_password_step1_text": "Digite uma senha",
    "dashboard_create_new_password_step2_text": "Repita a senha",
    "dashboard_createpassword_step1_text": "Digite uma nova senha para sua conta",
    "dashboard_createpassword_step1_title": "Nova senha",
    "dashboard_createpassword_step2_text": "Agora você já pode fazer seu login com sua nova senha",
    "dashboard_createpassword_step2_title": "Nova senha criada com sucesso",
    "dashboard_dict_added_increase": "Você adicionou mais",
    "dashboard_dict_added_words": "palavras ao seu vocabulário.",
    "dashboard_dict_added_words_singular": "palavra ao seu vocabulário.",
    "dashboard_dict_back_homepage": "Voltar à página inicial",
    "dashboard_dict_congrats": "Parabéns!",
    "dashboard_dict_obs": "Observações",
    "dashboard_dict_restart": "Iniciar novo ditado",
    "dashboard_dict_see_obs": "Ver observações",
    "dashboard_dict_see_translate": "Ver tradução",
    "dashboard_dict_translate": "Tradução",
    "dashboard_dict_writing_handed": "Escrever à mão",
    "dashboard_dict_writing_meta": "Meta",
    "dashboard_dict_writing_progress": "Seu progresso",
    "dashboard_dict_writing_special": "Caracteres especiais",
    "dashboard_dict_writing_step_1": "1. Baixe o app Mobdict Image Sender na App Store ou Play Store e faça login.",
    "dashboard_dict_writing_step_1_web": "1. Escaneie o QR Code ao lado. Você será direcionado para o site Mobdict Image Sender.",
    "dashboard_dict_writing_step_2": "2. Escreva o ditado que você ouviu em um papel e tire uma foto.",
    "dashboard_dict_writing_step_2_web": "2. Escreva à mão o ditado que você ouviu e tire uma foto.",
    "dashboard_dict_writing_step_3": "3. Envie a imagem pelo aplicativo e clique no botão ao lado para finalizar.",
    "dashboard_dict_writing_step_3_web": "3. Envie a imagem pelo site e clique no botão ao lado para finalizar.",
    "dashboard_dict_writing_step_action": "Já enviei",
    "dashboard_dict_writing_step_confirm": "Já conferi, Corrigir",
    "dashboard_dict_writing_title": "Digite o texto",
    "dashboard_dict_writing_words": "palavras",
    "dashboard_dict_writing_words_singular": "palavra",
    "dashboard_dict_wrong_challange": "Desafio",
    "dashboard_dict_wrong_finish": "Finalizar",
    "dashboard_dict_wrong_omit": "palavras omitidas",
    "dashboard_dict_wrong_omit_singular": "palavra omitida",
    "dashboard_dict_wrong_reinforcement": "Fazer ditado de reforço",
    "dashboard_dict_wrong_words": "palavras estão incorretas",
    "dashboard_dict_wrong_words_singular": "palavra está incorreta",
    "dashboard_forgot_step1_text": "Digite seu e-mail cadastrado para recuperar sua senha",
    "dashboard_forgot_step1_title": "Esqueceu sua senha?",
    "dashboard_forgot_step1_title_afirm": "Esqueci minha senha",
    "dashboard_forgot_step2_text": "Acesse o link de recuperação que foi enviado para seu email cadastrado",
    "dashboard_forgot_step2_title": "Verifique seu email",
    "dashboard_form_account_title": "Minha conta",
    "dashboard_form_back": "Voltar",
    "dashboard_form_balance_title": "Controle de créditos",
    "dashboard_form_cancel": "Cancelar",
    "dashboard_form_confirm_password": "Repita a senha",
    "dashboard_form_delete_checkbox": "Exclusão de conta",
    "dashboard_form_delete_confirmar": "Confirmar",
    "dashboard_form_delete_msg": "Após a confirmação, sua solicitação será encaminhada à nossa equipe. A exclusão da conta e dos seus dados será concluída em até 72 horas de forma definitiva e irreversível. Assim que o processo for finalizado, você receberá um e-mail de confirmação. Ao clicar em \"Continuar\", você concorda com estes termos.",
    "dashboard_form_email": "Email",
    "dashboard_form_level": "Nível",
    "dashboard_form_name": "Nome completo",
    "dashboard_form_next": "Continuar",
    "dashboard_form_nickname": "Escolha seu username (Apelido)",
    "dashboard_form_password": "Senha",
    "dashboard_form_password_title": "Alterar senha",
    "dashboard_form_password_validation": "Nova senha e confirmação da nova senha não são iguais",
    "dashboard_form_phone": "Celular",
    "dashboard_form_save": "Salvar",
    "dashboard_form_subgender": "Selecione os subgêneros literários",
    "dashboard_form_teacher": "Código do professor",
    "dashboard_home_cta": "Vamos começar o ditado",
    "dashboard_home_dicts_days": "dias restantes",
    "dashboard_home_dicts_dict": "Ditados",
    "dashboard_home_dicts_done": "realizados",
    "dashboard_home_dicts_plan": "Ditados contratados",
    "dashboard_home_dicts_test": "Período de teste",
    "dashboard_home_dicts_test_done": "Período de teste concluído",
    "dashboard_home_learning": "Estou aprendendo",
    "dashboard_home_level": "Nível",
    "dashboard_home_ranking_name": "Nome",
    "dashboard_home_ranking_points": "Pontuação",
    "dashboard_home_ranking_position": "Posição",
    "dashboard_home_ranking_see_more": "Ver mais",
    "dashboard_home_ranking_title": "Classificação",
    "dashboard_home_see_plans": "Ver planos",
    "dashboard_home_teacher": "Professor",
    "dashboard_no_plans": "Planos disponíveis em breve.",
    "dashboard_password_title": "Crie sua senha",
    "dashboard_plan_action": "Assine",
    "dashboard_plan_benefits": "Ver benefícios",
    "dashboard_plan_currency": "R$",
    "dashboard_plan_dict": "ditados",
    "dashboard_plan_free_action": "Testar",
    "dashboard_plan_name": "Grátis",
    "dashboard_plan_pop": "Mais popular",
    "dashboard_plan_subtitle": "cobrados anualmente",
    "dashboard_plan_title": "Assine um dos nossos planos",
    "dashboard_qtdade_TD": "test drive",
    "dashboard_ranking_empty": "Nenhuma informação encontrada",
    "dashboard_register_and": "e",
    "dashboard_register_consent_term_1": "Autorizo o compartilhamento dos itens  selecionados com outros usuários da plataforma.",
    "dashboard_register_gallery_term_1": "Desejo aparecer na galeria de professores de idiomas.",
    "dashboard_register_level": "Selecione o idioma e nível",
    "dashboard_register_step0_student": "Aluno",
    "dashboard_register_step0_teacher": "Professor",
    "dashboard_register_step0_text": "Informe em qual categoria deseja se cadastrar",
    "dashboard_register_step0_title": "Defina seu perfil",
    "dashboard_register_step1_action": "Continuar",
    "dashboard_register_step1_text": "Informe seus dados cadastrais",
    "dashboard_register_step1_title": "Digite seus dados",
    "dashboard_register_step2_action": "Fechar",
    "dashboard_register_step2_back": "Voltar",
    "dashboard_register_step2_text": "Obrigado por se cadastrar.",
    "dashboard_register_step2_title": "Cadastro finalizado com sucesso",
    "dashboard_register_subgender": "Selecione os subgêneros literários",
    "dashboard_register_subtitle": "Informe seus dados cadastrais",
    "dashboard_register_successfull": "Conta criada com sucesso",
    "dashboard_register_teacher_code": "Identifique seu professor",
    "dashboard_register_teacher_step1_text": "Informe suas redes sociais e uma foto de perfil",
    "dashboard_register_teacher_step1_title": "Redes sociais",
    "dashboard_register_terms_1": "Ao selecionar e continuar, você concorda com nossos",
    "dashboard_register_terms_2": "Termos de serviço",
    "dashboard_register_terms_3": "Política de privacidade",
    "dashboard_register_title": "Digite seus dados",
    "dashboard_register_validation_accept_terms": "Aceite os termos",
    "dashboard_register_validation_email": "Informe o email",
    "dashboard_register_validation_not_match": "Nova senha e confirmação da nova senha não são iguais",
    "dashboard_register_validation_password": "Informe a nova senha",
    "dashboard_register_validation_password_confirmation": "Informe a confirmação da nova senha",
    "dashboard_send_dict_all": "Todos",
    "dashboard_send_dict_students_clean": "Limpar",
    "dashboard_send_dict_students_subtitle": "Escolha para quais alunos deseja enviar este ditado",
    "dashboard_send_dict_students_title": "Selecionar alunos",
    "dashboard_send_dict_title": "Escrever seu ditado",
    "dashboard_settings_2fa": "Habilitar Autenticação de Dois Fatores",
    "dashboard_settings_active": "Ativo na galeria",
    "dashboard_settings_adjust_pause": "Ajustar pausa no ditado",
    "dashboard_settings_consent": "Redefinir permissões",
    "dashboard_settings_consent_title": "Selecione as permissões",
    "dashboard_settings_default_lang": "Idioma padrão",
    "dashboard_settings_define": "Definir",
    "dashboard_settings_languages": "Redefinir idiomas de ensino",
    "dashboard_settings_learn_lang": "Idioma aprendizado",
    "dashboard_settings_native_lang": "Idioma nativo",
    "dashboard_settings_pause": "Pausa",
    "dashboard_settings_recurrency": "Assinatura recorrente",
    "dashboard_settings_subgender": "Redefinir subgêneros literários",
    "dashboard_settings_title": "Configurações",
    "dashboard_side_account": "Minha conta",
    "dashboard_side_balance": "Balanço",
    "dashboard_side_buy": "Adicionar Ditados",
    "dashboard_side_dict": "Publicar Ditado",
    "dashboard_side_exit": "Sair",
    "dashboard_side_faq": "FAQ",
    "dashboard_side_home": "Home",
    "dashboard_side_ranking": "Classificação",
    "dashboard_side_settings": "Configurações",
    "dashboard_side_students": "Meus alunos",
    "dashboard_side_talk_us": "Fale conosco",
    "dashboard_side_teachers": "Galeria de Professores",
    "dashboard_side_tutorial": "Tutoriais",
    "dashboard_subtitle_subgender": "mínimo 3",
    "dashboard_table_balance_1": "Ditados Contratados",
    "dashboard_table_balance_2": "Ditados Gerados e não Realizados",
    "dashboard_table_balance_3": "Ditados Realizados",
    "dashboard_table_balance_4": "Ditados disponíveis",
    "dashboard_table_balance_desc": "Descrição",
    "dashboard_table_balance_qtd": "Quantidade",
    "dashboard_talkus_about_text": "Somos apaixonados por idiomas e educação. Nosso objetivo é tornar o aprendizado acessível e divertido para todos, utilizando uma abordagem inovadora baseada em ditados. No Mobdict®, transformar seu aprendizado de idiomas é mais fácil e divertido com ditados! Estas pequenas pérolas de sabedoria oferecem uma forma única de absorver a cultura e a linguagem. Ao aprender um novo idioma, descubra ditados que refletem o modo de pensar e viver dos falantes nativos. Cada expressão traz uma lição valiosa e contexto cultural, ajudando você a dominar nuances e a soar mais natural.",
    "dashboard_talkus_about_title": "Quem somos",
    "dashboard_talkus_email": "Email",
    "dashboard_talkus_support_address": "Endereço",
    "dashboard_talkus_support_email": "Suporte técnico",
    "dashboard_talkus_support_social": "Redes sociais",
    "dashboard_talkus_title": "Fale conosco",
    "dashboard_teacher_above_avg": "acima da média geral",
    "dashboard_teacher_average": "em média",
    "dashboard_teacher_below_avg": "abaixo da média geral",
    "dashboard_teacher_dicts_title": "Ditados Realizados por Aluno",
    "dashboard_teacher_show_more": "Ver mais",
    "dashboard_teacher_words_title": "Palavras Assimiladas por Aluno",
    "dashboard_teacher_wrong_subtitle": "ocorrências",
    "dashboard_teacher_wrong_title": "Palavras mais Erradas - Seus alunos",
    "dashboard_teacher_wrong_title_general": "Palavras mais Erradas - Geral",
    "dict_a_newdict": "Iniciar um novo ditado",
    "dict_by": "Ditado por:",
    "dict_correct": "Corrigir",
    "dict_finish": "Finalizar",
    "dict_form_dict": "Ditado",
    "dict_form_dict_error": "Insira o texto",
    "dict_form_obs": "Observações",
    "dict_form_ref": "Referência (Digite 'N/A' caso não possua)",
    "dict_form_reference_error": "Insira a referência.",
    "dict_form_select_error": "Selecione ao menos um aluno.",
    "dict_next": "Continuar",
    "dict_reference": "Referência:",
    "dict_reinforcement": "Ditado de reforço",
    "dict_word_bellow": "Utilize as palavras abaixo para completar o texto",
    "faq_title": "FAQ - Perguntas frequentes",
    "footer_all_rights": "Direitos reservados",
    "footer_support": "Suporte",
    "footer_talk_us": "Fale conosco",
    "gallery_consent_modal_title": "Termo de Uso e Isenção de Responsabilidade",
    "gallery_info_title": "Informações para Contato",
    "gallery_languages": "Idiomas",
    "gallery_score": "Score",
    "galley_student_count": "aluno no Mobdict",
    "galley_students_count": "alunos no Mobdict",
    "helpicon_languages": "Marque  os idiomas que leciona",
    "helpicon_level": "Níveis de proficiência no aprendizado de idiomas, conforme o Quadro Europeu Comum de Referência para Línguas (CEFR - Common European Framework of Reference for Languages)",
    "helpicon_native_lang": "As traduções serão neste idioma.",
    "helpicon_score": "O score representa a média de palavras assimiladas por aluno deste professor",
    "helpicon_subgender": "Os ditados serão criados com base nessas escolhas, tornando-os mais interessantes e alinhados às suas preferências",
    "helpicon_teacher": "Se você já possui um professor, insira o código fornecido ou solicite-o diretamente a ele.",
    "helpicon_username": "Esta informação ficará visível para outros usuários do Mobdict",
    "Infantojuvenil": "Infantojuvenil",
    "landpage_banner_balloon": "Aprimore um novo idioma!",
    "landpage_download_button": "Download disponível na",
    "landpage_flags_title": "Aprimore um idioma com o Mobdict®",
    "landpage_free_action": "Quero experimentar",
    "landpage_free_text": "Você pode praticar o idioma que está estudando, no nível que se encontra, testando o Mobdict® gratuitamente por uma semana e fazer até 5\t ditados para ver seu vocabulário aumentar a cada desafio.",
    "landpage_free_title": "Faça um teste gratuito",
    "landpage_how_works_action": "Quero conhecer os planos",
    "landpage_how_works_text": "Somos apaixonados por idiomas e educação. Nosso objetivo é tornar o aprendizado acessível e divertido para todos, utilizando uma abordagem inovadora baseada em ditados. Somos apaixonados por idiomas e educação. Nosso objetivo é tornar o aprendizado acessível e divertido para todos, utilizando uma abordagem inovadora baseada em ditados.",
    "landpage_how_works_title": "Nossa maior satisfação é ajudar no aprendizado de um novo idioma",
    "landpage_learn_action": "Começar agora",
    "landpage_learn_text": "Nosso método de ensino se concentra em praticar idiomas por meio de ditados, uma técnica que combina compreensão auditiva, leitura e prática escrita para melhorar suas habilidades linguísticas de forma natural e eficaz.",
    "landpage_learn_title": "Aprenda muito mais 😎",
    "landpage_more_text": "Assista nosso vídeo para entender como a aprendizagem de idiomas utilizando ditados pode beneficiar você.",
    "landpage_more_title": "Aprenda da melhor forma 🎬",
    "landpage_write_text": "Escrever à mão pode melhorar a retenção e a compreensão do vocabulário, pois envolve processos cognitivos mais profundos. Envie seus manuscritos para o Mobdict® usando o app Mobdict Image Sender.",
    "landpage_write_title": "Escreva à Mão e Aprenda mais com Mobdict®",
    "lg-acc": "Cadastre-se",
    "lg-acc-txt": "Ainda não tem uma conta? <a id=\"lg-acc\" href=\"/apl/register\">Cadastre-se</a>",
    "lg-auth-text": "Com o aplicativo <strong>Google Authenticator</strong>, ou outro aplicativo de autenticação, escaneie o QRCode abaixo para gerar seu token",
    "lg-email": "Email",
    "lg-error-pc": "Preencha todos os campos",
    "lg-error-pc-cloudflare": "Aguarde a verificação ou marque o checkox do Cloudflare.",
    "lg-error-pc-token": "Preencha o campo",
    "lg-fgp": "Esqueceu sua senha?",
    "lg-fgpx": "Esqueceu sua senha?",
    "lg-google-auth": "Insira o código disponível no aplicativo de autenticação",
    "lg-idioma": "Idioma",
    "lg-legend-login": "Login",
    "lg-legend-token": "Insira seu token",
    "lg-senha": "Senha",
    "lg-token": "Token",
    "login": "Entrar",
    "login_mfa": "Utilize o aplicativo de autenticação de sua preferência e escaneie o QRCode abaixo para gerar seu token",
    "login_mfa_qrcode": "Gerar novo QR Code",
    "login-2": "Autenticando...",
    "login-alt": "Entrar com",
    "login-signup": "Não tem uma conta?",
    "notifications_modal_all_read": "Marcar tudo como lido",
    "notifications_modal_any": "Você não tem notificações.",
    "notifications_modal_button_1": "Todas",
    "notifications_modal_button_2": "Não lidas",
    "notifications_modal_message": "Novo ditado disponível!",
    "notifications_modal_time_more": "Há ** dias",
    "notifications_modal_time_one": "Há 1 dia",
    "notifications_modal_time_today": "Hoje",
    "notifications_modal_title": "Notificações",
    "payment_promo_code": "Código Promocional",
    "payments_copy_paste": "Pix Copia e Cola",
    "payments_copy_paste_tooltip": "Copiado",
    "pix_error": "Erro ao gerar Qr Code Pix",
    "pix_success": "Cobrança Pix criada com sucesso",
    "plan_teacher_benefits_1": "Experiência prática – Realize até 5 ditados gratuitos para explorar a ferramenta do ponto de vista dos seus alunos.",
    "plan_teacher_benefits_2": "Acompanhamento do progresso – Após a validação do seu perfil como professor, acompanhe o desenvolvimento dos seus alunos assinantes em tempo real.",
    "plan_teacher_benefits_3": "Visibilidade e oportunidades – Divulgue seus serviços e redes sociais diretamente na plataforma, conectando-se com alunos que buscam cursos ou aulas particulares.",
    "plan_teacher_benefits_cta": "Junte-se ao Mobdict e amplie suas possibilidades como educador!",
    "plan_teacher_benefits_text": "Ao se cadastrar na plataforma, você terá acesso a recursos exclusivos para otimizar sua experiência e potencializar o aprendizado dos seus alunos:",
    "plan_teacher_benefits_title": "Benefícios para Professores no Mobdict",
    "plans_form_payments": "Forma de pagamento",
    "plans_payment_card": "Cartão de Crédito",
    "register_validation_language": "Selecione ao menos um idioma.",
    "register_validation_subgender": "Selecione pelo menos 3 subgêneros para continuar",
    "search_label_teachers": "Buscar um professor",
    "search_placeholder": "Pesquisar...",
    "settings_saved": "Alterações realizadas. Clique em 'Continuar' para salvá-las.",
    "settings_teacher_warning": "O professor selecionado não ensina este idioma. Será necessário inserir outro código (deixe em branco caso queira prosseguir sem um professor).",
    "settings_teacher_warning_error": "Professor ou idioma não alterados.",
    "settings_teacher_warning_title": "Altere seu professor",
    "students_dict": "ditados realizados",
    "students_known": "Palavras assimiladas:",
    "students_label_green": "Palavra assimilada",
    "students_label_red": "Palavra não assimilada",
    "students_languages": "Idiomas de aprendizado:",
    "students_title": "Meus Alunos",
    "students_wrong": "Palavras mais erradas:",
    "teacher_cv_title": "Informe um breve currículo",
    "teacher_form_city": "Cidade",
    "teacher_form_country": "País",
    "teacher_form_cv": "Currículo",
    "teacher_form_facebook": "Facebook",
    "teacher_form_instagram": "Instagram",
    "teacher_form_language": "Idiomas lecionados",
    "teacher_form_linkedin": "LinkedIn",
    "teacher_form_photo": "Foto",
    "teacher_form_social": "Redes sociais",
    "teacher_form_state": "Unidade federativa",
    "teacher_form_youtube": "YouTube",
    "teacher_location_title": "Nacionalidade",
    "teacher_pix_checkout_error": "Você não pode assinar um plano como Professor.",
    "teacher_register_consent_error": "Selecione pelo menos uma forma de contato (e-mail ou celular)",
    "teacher_register_consent_location": "Nacionalidade",
    "teacher_register_consent_term_error": "Aceite o termo",
    "teacher_register_info_1": "As informações do seu perfil serão visíveis para todos os usuários. Escolha quais deseja compartilhar:",
    "teacher_register_info_2": "ATENÇÃO: Após a ativação do perfil como Professor, você não poderá mais realizar ditados na plataforma, mas poderá acompanhar o desempenho de seus alunos.",
    "teacher_register_language_error": "Insira pelo menos um idioma",
    "teacher_register_languages": "Idiomas",
    "teacher_register_languages_subtitle": "Marque os idiomas que leciona",
    "teacher_register_photo_error": "Falha ao salvar imagem",
    "teacher_register_photo_type_error": "Formato inválido. Utilize png, jpeg ou jpg",
    "teacher_students_subtitle": "Legenda",
    "teachers_title": "Galeria de Professores",
    "title": "Bem-vindos",
    "val-token": "Validar",
    "val-token-2": "Validando...",
    "x26": "Ao selecionar e continuar, você concorda com nossos Termos de serviço, Política de privacidade e LGPD",
    "x9": "Ao selecionar e continuar, você concorda com nossos Termos de serviço e Política de privacidade"
}