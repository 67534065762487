export const static_translation = {
    "bar_chart_general": "一般",
    "bar_chart_students": "你的学生",
    "dashboard_audio_player_ditado": "暂停",
    "dashboard_audio_player_original": "原始 ",
    "dashboard_become_teacher": "激活教师档案",
    "dashboard_create_new_password_check1_text": "大写字母",
    "dashboard_create_new_password_check2_text": "数字",
    "dashboard_create_new_password_check3_text": "至少6个字符",
    "dashboard_create_new_password_check4_text": "特殊字符",
    "dashboard_create_new_password_step1_text": "输入密码",
    "dashboard_create_new_password_step2_text": "\t重复密码",
    "dashboard_createpassword_step1_text": "为您的账户输入新密码",
    "dashboard_createpassword_step1_title": "新密码",
    "dashboard_createpassword_step2_text": "现在您可以使用新密码登录",
    "dashboard_createpassword_step2_title": "新密码创建成功",
    "dashboard_dict_added_increase": "你添加了更多",
    "dashboard_dict_added_words": "单词到你的词汇表。",
    "dashboard_dict_added_words_singular": "词汇添加到你的词汇中.",
    "dashboard_dict_back_homepage": "返回主页",
    "dashboard_dict_congrats": "恭喜！",
    "dashboard_dict_obs": "备注",
    "dashboard_dict_restart": "开始新的听写",
    "dashboard_dict_see_obs": "查看备注",
    "dashboard_dict_see_translate": "查看翻译",
    "dashboard_dict_translate": "翻译",
    "dashboard_dict_writing_handed": "手写 ",
    "dashboard_dict_writing_meta": "目标 ",
    "dashboard_dict_writing_progress": "你的进展 ",
    "dashboard_dict_writing_special": "特殊字符",
    "dashboard_dict_writing_step_1": "1. 从App Store或Play Store下载Mobdict Image Sender应用并登录。",
    "dashboard_dict_writing_step_1_web": "1. 扫描旁边的二维码。您将被重定向到 Mobdict Image Sender 网站。",
    "dashboard_dict_writing_step_2": "2. 将您听到的听写内容写在纸上并拍照。",
    "dashboard_dict_writing_step_2_web": "2. 手写下您听到的听写，并拍一张照片。",
    "dashboard_dict_writing_step_3": "3. 通过应用发送图片并点击旁边的按钮完成。",
    "dashboard_dict_writing_step_3_web": "3. 通过网站发送图片，然后点击旁边的按钮完成。",
    "dashboard_dict_writing_step_action": "已发送",
    "dashboard_dict_writing_step_confirm": "我已检查，纠正",
    "dashboard_dict_writing_title": "输入文本",
    "dashboard_dict_writing_words": "词 ",
    "dashboard_dict_writing_words_singular": "词",
    "dashboard_dict_wrong_challange": "挑战",
    "dashboard_dict_wrong_finish": "完成",
    "dashboard_dict_wrong_omit": "省略的词语",
    "dashboard_dict_wrong_omit_singular": "省略的词",
    "dashboard_dict_wrong_reinforcement": "做强化听写",
    "dashboard_dict_wrong_words": "个单词不正确",
    "dashboard_dict_wrong_words_singular": "单词不正确",
    "dashboard_forgot_step1_text": "输入您注册的电子邮件以找回密码",
    "dashboard_forgot_step1_title": "忘记密码了吗？",
    "dashboard_forgot_step1_title_afirm": "我忘记了密码",
    "dashboard_forgot_step2_text": "访问发送到您注册邮箱的恢复链接",
    "dashboard_forgot_step2_title": "检查你的电子邮件",
    "dashboard_form_account_title": "我的账户",
    "dashboard_form_back": "返回",
    "dashboard_form_balance_title": "信用控制",
    "dashboard_form_cancel": "取消",
    "dashboard_form_confirm_password": "重复密码",
    "dashboard_form_delete_checkbox": "账户删除",
    "dashboard_form_delete_confirmar": "确认",
    "dashboard_form_delete_msg": "确认后，您的请求将被转交给我们的团队。帐户和数据删除将在 72 小时内完成，且不可撤销。一旦流程完成，您将收到确认电子邮件。点击“继续”即表示您同意这些条款。",
    "dashboard_form_email": "电子邮件",
    "dashboard_form_level": "等级",
    "dashboard_form_name": "全名",
    "dashboard_form_next": "继续",
    "dashboard_form_nickname": "选择用户名 (昵称)",
    "dashboard_form_password": "密码",
    "dashboard_form_password_title": "更改密码",
    "dashboard_form_password_validation": "新密码与确认密码不一致",
    "dashboard_form_phone": "移动电话",
    "dashboard_form_save": "保存",
    "dashboard_form_subgender": "选择文学子类别",
    "dashboard_form_teacher": "教师代码",
    "dashboard_home_cta": "让我们开始听写",
    "dashboard_home_dicts_days": "剩余天数",
    "dashboard_home_dicts_dict": "口述",
    "dashboard_home_dicts_done": "已完成",
    "dashboard_home_dicts_plan": "合同听写",
    "dashboard_home_dicts_test": "试用期",
    "dashboard_home_dicts_test_done": "试用期结束",
    "dashboard_home_learning": "我在学习",
    "dashboard_home_level": "水平",
    "dashboard_home_ranking_name": "姓名",
    "dashboard_home_ranking_points": "得分",
    "dashboard_home_ranking_position": "排名",
    "dashboard_home_ranking_see_more": "查看更多",
    "dashboard_home_ranking_title": "排名",
    "dashboard_home_see_plans": "查看计划",
    "dashboard_home_teacher": "教授",
    "dashboard_no_plans": "计划即将推出。",
    "dashboard_password_title": "创建您的密码",
    "dashboard_plan_action": "订阅",
    "dashboard_plan_benefits": "查看福利",
    "dashboard_plan_currency": "R$",
    "dashboard_plan_dict": "谚语",
    "dashboard_plan_free_action": "测试",
    "dashboard_plan_name": "免费",
    "dashboard_plan_pop": "最受欢迎",
    "dashboard_plan_subtitle": " 每年收取",
    "dashboard_plan_title": "订阅我们的一个计划",
    "dashboard_qtdade_TD": "试用期",
    "dashboard_ranking_empty": "没有找到信息",
    "dashboard_register_and": " 和",
    "dashboard_register_consent_term_1": "我授权与平台上的其他用户共享所选项目。",
    "dashboard_register_gallery_term_1": "我希望出现在语言教师图库中。",
    "dashboard_register_level": "选择语言和级别",
    "dashboard_register_step0_student": "学生",
    "dashboard_register_step0_teacher": "教师",
    "dashboard_register_step0_text": "请指定您要注册的类别",
    "dashboard_register_step0_title": "定义您的个人资料",
    "dashboard_register_step1_action": "继续",
    "dashboard_register_step1_text": "提供您的注册信息",
    "dashboard_register_step1_title": "输入您的信息",
    "dashboard_register_step2_action": "关闭",
    "dashboard_register_step2_back": "返回",
    "dashboard_register_step2_text": "感谢您的注册。",
    "dashboard_register_step2_title": "注册成功完成",
    "dashboard_register_subgender": "选择文学子类别",
    "dashboard_register_subtitle": "输入您的注册信息",
    "dashboard_register_successfull": "账户创建成功",
    "dashboard_register_teacher_code": "识别您的老师",
    "dashboard_register_teacher_step1_text": "提供您的社交网络和个人照片",
    "dashboard_register_teacher_step1_title": "社交媒体",
    "dashboard_register_terms_1": "通过选择并继续，您同意我们的",
    "dashboard_register_terms_2": "服务条款",
    "dashboard_register_terms_3": "隐私政策",
    "dashboard_register_title": "输入您的数据",
    "dashboard_register_validation_accept_terms": "接受条款",
    "dashboard_register_validation_email": "输入电子邮件",
    "dashboard_register_validation_not_match": "新密码和确认不匹配",
    "dashboard_register_validation_password": "输入新密码 ",
    "dashboard_register_validation_password_confirmation": "输入新密码的确认",
    "dashboard_send_dict_all": "所有",
    "dashboard_send_dict_students_clean": "清除",
    "dashboard_send_dict_students_subtitle": "选择要发送此听写的学生",
    "dashboard_send_dict_students_title": "选择学生",
    "dashboard_send_dict_title": "写下你的听写",
    "dashboard_settings_2fa": "启用双因素身份验证",
    "dashboard_settings_active": "在画廊中激活",
    "dashboard_settings_adjust_pause": "调整听写暂停",
    "dashboard_settings_consent": "重置权限",
    "dashboard_settings_consent_title": "选择权限。",
    "dashboard_settings_default_lang": "默认语言",
    "dashboard_settings_define": "设置",
    "dashboard_settings_languages": "重置教学语言",
    "dashboard_settings_learn_lang": "学习语言",
    "dashboard_settings_native_lang": "母语",
    "dashboard_settings_pause": "暂停",
    "dashboard_settings_recurrency": "定期订阅",
    "dashboard_settings_subgender": "重置文学子类别",
    "dashboard_settings_title": "设置",
    "dashboard_side_account": "我的账户",
    "dashboard_side_balance": "资产负债表",
    "dashboard_side_buy": "添加谚语",
    "dashboard_side_dict": "发布听写",
    "dashboard_side_exit": "退出",
    "dashboard_side_faq": "常见问题解答",
    "dashboard_side_home": "主页",
    "dashboard_side_ranking": "排名",
    "dashboard_side_settings": "设置",
    "dashboard_side_students": "我的学生",
    "dashboard_side_talk_us": "联系我们",
    "dashboard_side_teachers": "教师画廊",
    "dashboard_side_tutorial": "教程",
    "dashboard_subtitle_subgender": "最少 3",
    "dashboard_table_balance_1": "合同听写",
    "dashboard_table_balance_2": "生成的听写未执行",
    "dashboard_table_balance_3": "完成的听写",
    "dashboard_table_balance_4": "可用听写",
    "dashboard_table_balance_desc": "描述",
    "dashboard_table_balance_qtd": "数量",
    "dashboard_talkus_about_text": "我们热爱语言和教育。我们的目标是通过基于谚语的创新方法，使学习变得对所有人更易接近且充满乐趣。在 Mobdict®，通过谚语使您的语言学习变得更加轻松有趣！这些智慧的结晶提供了一种独特的方式来吸收文化和语言。学习新语言时，探索反映母语者思维和生活方式的谚语。每个表达都带来宝贵的教训和文化背景，帮助您掌握细微差别，使您的表达更自然。",
    "dashboard_talkus_about_title": "关于我们",
    "dashboard_talkus_email": "电子邮件",
    "dashboard_talkus_support_address": "地址",
    "dashboard_talkus_support_email": "技术支持",
    "dashboard_talkus_support_social": "社交媒体",
    "dashboard_talkus_title": "联系我们",
    "dashboard_teacher_above_avg": "高于总体平均水平",
    "dashboard_teacher_average": "平均",
    "dashboard_teacher_below_avg": "低于总体平均水平",
    "dashboard_teacher_dicts_title": "学生完成的听写",
    "dashboard_teacher_show_more": "查看更多",
    "dashboard_teacher_words_title": "学生掌握的单词",
    "dashboard_teacher_wrong_subtitle": "事件",
    "dashboard_teacher_wrong_title": "拼写错误最多的单词 - 你的学生",
    "dashboard_teacher_wrong_title_general": "拼写错误最多的单词 - 一般",
    "dict_a_newdict": "开始新的听写",
    "dict_by": "由听写",
    "dict_correct": "纠正",
    "dict_finish": "结束",
    "dict_form_dict": "听写",
    "dict_form_dict_error": "输入文本。",
    "dict_form_obs": "备注",
    "dict_form_ref": "参考（若无请输入 'N/A'）",
    "dict_form_reference_error": "输入参考。",
    "dict_form_select_error": "选择至少一名学生。",
    "dict_next": "继续",
    "dict_reference": "参考:",
    "dict_reinforcement": "强化听写",
    "dict_word_bellow": "使用下面的词语完成文本",
    "faq_title": "FAQ - 常见问题",
    "footer_all_rights": "版权所有",
    "footer_support": "支持",
    "footer_talk_us": "联系我们",
    "gallery_consent_modal_title": "使用条款和免责声明",
    "gallery_info_title": "联系信息",
    "gallery_languages": "语言",
    "gallery_score": "分数",
    "galley_student_count": "Mobdict中的学生",
    "galley_students_count": "Mobdict上的学生",
    "helpicon_languages": "标记您教授的语言",
    "helpicon_level": "根据《欧洲语言共同参考框架》（CEFR），语言学习的熟练程度",
    "helpicon_native_lang": "翻译将以此语言进行。",
    "helpicon_score": "分数代表该教师每个学生所掌握的平均单词数",
    "helpicon_subgender": "这些谚语将根据这些选择创建，使它们更有趣并符合您的喜好。",
    "helpicon_teacher": "如果您已经有老师，请输入提供的代码或直接向他们索取。",
    "helpicon_username": "该信息将对其他Mobdict用户可见。",
    "Infantojuvenil": "儿童与青少年",
    "landpage_banner_balloon": "提升一种新语言！",
    "landpage_download_button": "下载可用于",
    "landpage_flags_title": "通过Mobdict®提升一种语言：",
    "landpage_free_action": "我想试试",
    "landpage_free_text": "您可以在自己当前的水平上练习正在学习的语言，免费试用 Mobdict® 一周，并进行多达 5 次听写，以在每个挑战中提高词汇量。",
    "landpage_free_title": "免费试用",
    "landpage_how_works_action": "我想了解计划",
    "landpage_how_works_text": "我们热衷于语言和教育。我们的目标是通过一种基于听写的创新方法，使学习变得对每个人都可及且有趣。",
    "landpage_how_works_title": "我们最大的满足感是帮助学习一门新语言",
    "landpage_learn_action": "立即开始",
    "landpage_learn_text": "我们的教学方法侧重于通过听写练习语言，这是一种结合听力理解、阅读和写作练习的技术，可以自然有效地提高您的语言能力。",
    "landpage_learn_title": "学得更多 😎",
    "landpage_more_text": "观看我们的视频，了解如何通过使用听写学习语言可以为您带来好处。",
    "landpage_more_title": "以最好的方式学习 🎬",
    "landpage_write_text": "手写可以改善词汇的记忆和理解，因为它涉及到更深层次的认知过程。通过Mobdict®应用发送您的手稿到Mobdict。",
    "landpage_write_title": "手写并通过 Mobdict® 学习更多",
    "lg-acc": "注册",
    "lg-acc-txt": "还没有账户吗? <a id=\"lg-acc\" href=\"/apl/register\">注册</a>",
    "lg-auth-text": "<strong>Google Authenticator</strong> 应用程序或其他身份验证应用程序，扫描下面的二维码以生成您的令牌",
    "lg-email": "电子邮件",
    "lg-error-pc": "填写所有字段",
    "lg-error-pc-cloudflare": "等待验证或勾选Cloudflare复选框。",
    "lg-error-pc-token": "填写字段",
    "lg-fgp": "忘记密码了吗?",
    "lg-fgpx": "忘记密码了吗？",
    "lg-google-auth": "输入身份验证应用程序中的代码",
    "lg-idioma": "语言",
    "lg-legend-login": "登录",
    "lg-legend-token": "输入您的令牌",
    "lg-senha": "密码",
    "lg-token": "令牌",
    "login": "登录",
    "login_mfa": "使用您选择的身份验证应用程序并扫描下面的二维码以生成您的令牌 ",
    "login_mfa_qrcode": "生成新的二维码",
    "login-2": "认证中...",
    "login-alt": "带入",
    "login-signup": "没有账户？",
    "notifications_modal_all_read": "标记全部为已读",
    "notifications_modal_any": "你没有通知。",
    "notifications_modal_button_1": "全部",
    "notifications_modal_button_2": "未读",
    "notifications_modal_message": "有新的听写！",
    "notifications_modal_time_more": "**天前",
    "notifications_modal_time_one": "1天前",
    "notifications_modal_time_today": "今天",
    "notifications_modal_title": "通知",
    "payment_promo_code": "优惠码",
    "payments_copy_paste": "Pix Copia e Cola",
    "payments_copy_paste_tooltip": "Copiado",
    "pix_error": "Erro ao gerar Qr Code Pix",
    "pix_success": "Cobrança Pix criada com sucesso",
    "plan_teacher_benefits_1": "实践经验 – 完成最多 5 次免费听写，以从您的学生角度探索该工具。",
    "plan_teacher_benefits_2": "进度跟踪 – 在验证您的教师身份后，实时跟踪您的订阅学生的发展。",
    "plan_teacher_benefits_3": "可见性和机会 – 在平台上直接推广您的服务和社交网络，与寻找课程或私人课程的学生建立联系。",
    "plan_teacher_benefits_cta": "加入 Mobdict，拓展您的教育者可能性！",
    "plan_teacher_benefits_text": "注册该平台后，您将能够访问专属功能，以优化您的体验并增强学生的学习能力。",
    "plan_teacher_benefits_title": "Mobdict 上的教师福利",
    "plans_form_payments": "支付方式",
    "plans_payment_card": "信用卡",
    "register_validation_language": "至少选择一种语言。",
    "register_validation_subgender": "选择至少 3 个子类别以继续",
    "search_label_teachers": "寻找老师",
    "search_placeholder": "搜索...",
    "settings_saved": "更改已完成。点击“继续”保存更改。",
    "settings_teacher_warning": "所选教师不教授该语言。请输入另一个代码（如果希望在没有教师的情况下继续，请留空）。",
    "settings_teacher_warning_error": "教师或语言未更改。",
    "settings_teacher_warning_title": "更改您的教师",
    "students_dict": "已完成听写",
    "students_known": "已吸收单词:",
    "students_label_green": "已吸收单词",
    "students_label_red": "未吸收单词",
    "students_languages": "学习语言:",
    "students_title": "我的学生",
    "students_wrong": "最错误的词:",
    "teacher_cv_title": "提供简短的简历",
    "teacher_form_city": "城市",
    "teacher_form_country": "国家",
    "teacher_form_cv": "简历",
    "teacher_form_facebook": "Facebook",
    "teacher_form_instagram": "Instagram",
    "teacher_form_language": "教授的语言",
    "teacher_form_linkedin": "LinkedIn",
    "teacher_form_photo": "照片",
    "teacher_form_social": "社交媒体",
    "teacher_form_state": "联邦单位",
    "teacher_form_youtube": "Youtube",
    "teacher_location_title": "国籍",
    "teacher_pix_checkout_error": "您不能以教师身份订阅计划。",
    "teacher_register_consent_error": "选择至少一种联系方式（电子邮件或手机）",
    "teacher_register_consent_location": "国籍",
    "teacher_register_consent_term_error": "接受条款",
    "teacher_register_info_1": "您的个人资料信息将对所有用户可见。选择您想分享的内容：",
    "teacher_register_info_2": "注意：激活教师身份后，您将无法在平台上进行听写，但可以跟踪学生的表现。",
    "teacher_register_language_error": "输入至少一种语言",
    "teacher_register_languages": "语言",
    "teacher_register_languages_subtitle": "标记您教授的语言",
    "teacher_register_photo_error": "保存图像失败",
    "teacher_register_photo_type_error": "无效格式。请使用png、jpeg或jpg",
    "teacher_students_subtitle": "标题",
    "teachers_title": "教师画廊",
    "title": "欢迎",
    "val-token": "验证",
    "val-token-2": "验证中...",
    "x26": "选择并继续即表示您同意我们的服务条款，隐私政策和 LGPD",
    "x9": "通过选择并继续，您同意我们的服务条款和隐私政策"
}