export const static_translation = {
    "bar_chart_general": "सामान्य",
    "bar_chart_students": "आपके छात्र",
    "dashboard_audio_player_ditado": "रुका हुआ",
    "dashboard_audio_player_original": "मूल ",
    "dashboard_become_teacher": "प्रोफेसर प्रोफाइल सक्रिय करें",
    "dashboard_create_new_password_check1_text": "अक्षर",
    "dashboard_create_new_password_check2_text": "संख्या",
    "dashboard_create_new_password_check3_text": "कम से कम 6 वर्ण",
    "dashboard_create_new_password_check4_text": "विशेष चरित्र",
    "dashboard_create_new_password_step1_text": "पासवर्ड दर्ज करें",
    "dashboard_create_new_password_step2_text": "पासवर्ड दोबारा दर्ज करें",
    "dashboard_createpassword_step1_text": "अपने खाते के लिए एक नया पासवर्ड दर्ज करें",
    "dashboard_createpassword_step1_title": "नया पासवर्ड",
    "dashboard_createpassword_step2_text": "अब आप अपने नए पासवर्ड से लॉग इन कर सकते हैं",
    "dashboard_createpassword_step2_title": "नई पासवर्ड सफलतापूर्वक बनाया गया",
    "dashboard_dict_added_increase": "आपने और जोड़ा",
    "dashboard_dict_added_words": "शब्द अपने शब्दकोश में।",
    "dashboard_dict_added_words_singular": "शब्द को अपनी शब्दावली में.",
    "dashboard_dict_back_homepage": "मुखपृष्ठ पर वापस जाएं",
    "dashboard_dict_congrats": "बधाई हो!",
    "dashboard_dict_obs": "टिप्पणियाँ",
    "dashboard_dict_restart": "नया श्रुतलेख शुरू करें",
    "dashboard_dict_see_obs": "पर्यवेक्षण देखें",
    "dashboard_dict_see_translate": "अनुवाद देखें",
    "dashboard_dict_translate": "अनुवाद",
    "dashboard_dict_writing_handed": "हस्तलिपि",
    "dashboard_dict_writing_meta": "लक्ष्य ",
    "dashboard_dict_writing_progress": "आपकी प्रगति",
    "dashboard_dict_writing_special": "विशेष वर्ण",
    "dashboard_dict_writing_step_1": "1. App Store या Play Store से Mobdict Image Sender ऐप डाउनलोड करें और लॉग इन करें।",
    "dashboard_dict_writing_step_1_web": "1. साथ वाले क्यूआर कोड को स्कैन करें। आपको मोबडिक्ट इमेज सेंडर वेबसाइट पर भेजा जाएगा।",
    "dashboard_dict_writing_step_2": "2. श्रुतलेख को एक कागज़ पर लिखें और एक फोटो खींचें।",
    "dashboard_dict_writing_step_2_web": "2. आपने जो श्रुतिलेख सुना उसे हाथ से लिखें और एक फ़ोटो लें।",
    "dashboard_dict_writing_step_3": "3. ऐप के माध्यम से छवि भेजें और समाप्त करने के लिए बटन पर क्लिक करें।",
    "dashboard_dict_writing_step_3_web": "3. साइट के माध्यम से छवि भेजें और समाप्त करने के लिए बगल के बटन पर क्लिक करें।",
    "dashboard_dict_writing_step_action": "पहले ही भेज दिया है",
    "dashboard_dict_writing_step_confirm": "मैंने जाँच कर ली है, सुधार करें",
    "dashboard_dict_writing_title": "पाठ दर्ज करें",
    "dashboard_dict_writing_words": "शब्द ",
    "dashboard_dict_writing_words_singular": "शब्द ",
    "dashboard_dict_wrong_challange": "चुनौती",
    "dashboard_dict_wrong_finish": "समाप्त करें",
    "dashboard_dict_wrong_omit": "छोड़े गए शब्द",
    "dashboard_dict_wrong_omit_singular": "छोड़ा हुआ शब्द",
    "dashboard_dict_wrong_reinforcement": "पुनर्बलन श्रुतलेख करें",
    "dashboard_dict_wrong_words": " शब्द गलत हैं",
    "dashboard_dict_wrong_words_singular": "शब्द गलत है",
    "dashboard_forgot_step1_text": "अपना पासवर्ड पुनर्प्राप्त करने के लिए अपना पंजीकृत ईमेल दर्ज करें",
    "dashboard_forgot_step1_title": "पासवर्ड भूल गए?",
    "dashboard_forgot_step1_title_afirm": "मैं अपना पासवर्ड भूल गया हूँ",
    "dashboard_forgot_step2_text": "अपने पंजीकृत ईमेल पर भेजे गए पुनर्प्राप्ति लिंक का उपयोग करें",
    "dashboard_forgot_step2_title": "अपना ईमेल चेक करें",
    "dashboard_form_account_title": "मेरा खाता",
    "dashboard_form_back": "वापस",
    "dashboard_form_balance_title": "क्रेडिट नियंत्रण",
    "dashboard_form_cancel": "रद्द करें",
    "dashboard_form_confirm_password": "पासवर्ड दोहराएं",
    "dashboard_form_delete_checkbox": "खाता हटाना",
    "dashboard_form_delete_confirmar": "पुष्टि करें",
    "dashboard_form_delete_msg": "पुष्टि के बाद, आपका अनुरोध हमारी टीम को भेजा जाएगा। खाते और आपके डेटा को 72 घंटों के भीतर स्थायी और अपरिवर्तनीय रूप से हटा दिया जाएगा। प्रक्रिया पूरी होने के बाद, आपको एक पुष्टि ईमेल प्राप्त होगा। 'जारी रखें' पर क्लिक करके, आप इन शर्तों से सहमत होते हैं।",
    "dashboard_form_email": "ईमेल",
    "dashboard_form_level": "स्तर",
    "dashboard_form_name": "पूरा नाम",
    "dashboard_form_next": "जारी रखें",
    "dashboard_form_nickname": "अपना उपयोगकर्ता नाम चुनें (उपनाम)",
    "dashboard_form_password": "पासवर्ड",
    "dashboard_form_password_title": "पासवर्ड बदलें",
    "dashboard_form_password_validation": "नया पासवर्ड और पासवर्ड की पुष्टि समान नहीं हैं",
    "dashboard_form_phone": "मोबाइल फोन",
    "dashboard_form_save": "सहेजें",
    "dashboard_form_subgender": "साहित्यिक उपश्रेणियों का चयन करें",
    "dashboard_form_teacher": "प्रोफेसर कोड",
    "dashboard_home_cta": "आइए तानाशाही शुरू करें",
    "dashboard_home_dicts_days": "शेष दिन",
    "dashboard_home_dicts_dict": "कथन",
    "dashboard_home_dicts_done": "पूरा किया गया",
    "dashboard_home_dicts_plan": "अनुबंधित श्रुतलेख",
    "dashboard_home_dicts_test": "परीक्षण अवधि",
    "dashboard_home_dicts_test_done": "परीक्षण अवधि समाप्त",
    "dashboard_home_learning": "मैं सीख रहा हूँ",
    "dashboard_home_level": "स्तर",
    "dashboard_home_ranking_name": "नाम",
    "dashboard_home_ranking_points": "स्कोर",
    "dashboard_home_ranking_position": "स्थान",
    "dashboard_home_ranking_see_more": "और देखें",
    "dashboard_home_ranking_title": "रैंकिंग",
    "dashboard_home_see_plans": "योजनाएँ देखें",
    "dashboard_home_teacher": "प्रोफेसर",
    "dashboard_no_plans": "योजनाएं जल्द ही उपलब्ध होंगी।",
    "dashboard_password_title": "अपना पासवर्ड बनाएं",
    "dashboard_plan_action": "सदस्यता लें",
    "dashboard_plan_benefits": "लाभ देखें",
    "dashboard_plan_currency": "R$",
    "dashboard_plan_dict": "कथन",
    "dashboard_plan_free_action": "परीक्षण",
    "dashboard_plan_name": "मुफ्त",
    "dashboard_plan_pop": "सबसे लोकप्रिय",
    "dashboard_plan_subtitle": "वार्षिक रूप से शुल्क लिया गया ",
    "dashboard_plan_title": "हमारे किसी एक प्लान को सब्सक्राइब करें",
    "dashboard_qtdade_TD": "परीक्षण अवधि",
    "dashboard_ranking_empty": "कोई जानकारी नहीं मिली",
    "dashboard_register_and": "और",
    "dashboard_register_consent_term_1": "मैं चयनित वस्तुओं को प्लेटफ़ॉर्म के अन्य उपयोगकर्ताओं के साथ साझा करने की अनुमति देता/देती हूं।",
    "dashboard_register_gallery_term_1": "मैं भाषा शिक्षकों की गैलरी में दिखना चाहता हूँ।",
    "dashboard_register_level": "भाषा और स्तर चुनें",
    "dashboard_register_step0_student": "छात्र",
    "dashboard_register_step0_teacher": "प्रोफेसर",
    "dashboard_register_step0_text": "कृपया बताएं कि आप किस श्रेणी में पंजीकरण करना चाहते हैं",
    "dashboard_register_step0_title": "अपना प्रोफ़ाइल सेट करें",
    "dashboard_register_step1_action": "जारी रखें",
    "dashboard_register_step1_text": "अपना पंजीकरण विवरण प्रदान करें",
    "dashboard_register_step1_title": "अपना डेटा दर्ज करें",
    "dashboard_register_step2_action": "बंद करें",
    "dashboard_register_step2_back": "वापस जाएं",
    "dashboard_register_step2_text": "पंजीकरण के लिए धन्यवाद।",
    "dashboard_register_step2_title": "पंजीकरण सफलतापूर्वक पूरा हुआ",
    "dashboard_register_subgender": "साहित्यिक उपश्रेणियों का चयन करें",
    "dashboard_register_subtitle": "अपनी पंजीकरण जानकारी दर्ज करें",
    "dashboard_register_successfull": "खाता सफलतापूर्वक बनाया गया",
    "dashboard_register_teacher_code": "अपने शिक्षक की पहचान करें",
    "dashboard_register_teacher_step1_text": "अपना सोशल नेटवर्क और प्रोफाइल फोटो दर्ज करें",
    "dashboard_register_teacher_step1_title": "सोशल मीडिया",
    "dashboard_register_terms_1": "चयन करके और जारी रखकर, आप हमारी स्वीकृति देते हैं",
    "dashboard_register_terms_2": " सेवा की शर्तें ",
    "dashboard_register_terms_3": "गोपनीयता नीति",
    "dashboard_register_title": "अपने डेटा दर्ज करें",
    "dashboard_register_validation_accept_terms": "शर्तें स्वीकार करें",
    "dashboard_register_validation_email": "ईमेल दर्ज करें",
    "dashboard_register_validation_not_match": "नया पासवर्ड और पुष्टि मेल नहीं खाते",
    "dashboard_register_validation_password": "नई पासवर्ड दर्ज करें",
    "dashboard_register_validation_password_confirmation": "नया पासवर्ड की पुष्टि दर्ज करें",
    "dashboard_send_dict_all": "सभी",
    "dashboard_send_dict_students_clean": "साफ़ करें",
    "dashboard_send_dict_students_subtitle": "उन छात्रों को चुनें जिन्हें आप यह श्रुतलेख भेजना चाहते हैं",
    "dashboard_send_dict_students_title": "छात्रों का चयन करें",
    "dashboard_send_dict_title": "अपना श्रुतलेख लिखें",
    "dashboard_settings_2fa": "दो-कारक प्रमाणीकरण सक्षम करें",
    "dashboard_settings_active": "गैलरी में सक्रिय",
    "dashboard_settings_adjust_pause": "डिक्टेशन पॉज़ समायोजित करें",
    "dashboard_settings_consent": "अनुमतियां पुनः सेट करें",
    "dashboard_settings_consent_title": "अनुमतियां चुनें।",
    "dashboard_settings_default_lang": "डिफ़ॉल्ट भाषा",
    "dashboard_settings_define": "सेट करें",
    "dashboard_settings_languages": "शिक्षण भाषाएं पुनः सेट करें",
    "dashboard_settings_learn_lang": "सीखने की भाषा",
    "dashboard_settings_native_lang": "मातृभाषा",
    "dashboard_settings_pause": "पॉज़",
    "dashboard_settings_recurrency": "आवर्ती सदस्यता",
    "dashboard_settings_subgender": "साहित्यिक उपश्रेणियों को पुनः सेट करें",
    "dashboard_settings_title": "सेटिंग्स",
    "dashboard_side_account": "मेरा खाता",
    "dashboard_side_balance": "बैलेंस शीट",
    "dashboard_side_buy": "कथन जोड़ें",
    "dashboard_side_dict": "श्रुतलेख प्रकाशित करें",
    "dashboard_side_exit": "लॉग आउट करें",
    "dashboard_side_faq": "सामान्य प्रश्न",
    "dashboard_side_home": "होम",
    "dashboard_side_ranking": "रैंकिंग",
    "dashboard_side_settings": "सेटिंग्स",
    "dashboard_side_students": "मेरे छात्र",
    "dashboard_side_talk_us": "हमसे संपर्क करें",
    "dashboard_side_teachers": "प्रोफेसरों की गैलरी",
    "dashboard_side_tutorial": "ट्यूटोरियल्स",
    "dashboard_subtitle_subgender": "न्यूनतम 3",
    "dashboard_table_balance_1": "अनुबंधित तानाशाह",
    "dashboard_table_balance_2": "उत्पन्न तानाशाह और निष्पादित नहीं",
    "dashboard_table_balance_3": "पूरा किया गया श्रुतलेख",
    "dashboard_table_balance_4": "उपलब्ध तानाशाह",
    "dashboard_table_balance_desc": "विवरण",
    "dashboard_table_balance_qtd": "मात्रा",
    "dashboard_talkus_about_text": "हम भाषाओं और शिक्षा के प्रति जुनूनी हैं। हमारा उद्देश्य सभी के लिए शिक्षा को सुलभ और मजेदार बनाना है, जिसका उपयोग हम एक अभिनव दृष्टिकोण द्वारा करते हैं जो कहावतों पर आधारित है। मोबडिक्ट® में, आपका भाषा सीखना कहावतों के साथ आसान और मजेदार हो जाता है! ये छोटी-छोटी ज्ञान की मोतियाँ संस्कृति और भाषा को अनोखे तरीके से आत्मसात करने का अवसर देती हैं। नई भाषा सीखते समय, उन कहावतों की खोज करें जो मूल वक्ताओं के सोचने और जीने के तरीके को दर्शाती हैं। प्रत्येक अभिव्यक्ति एक मूल्यवान सबक और सांस्कृतिक संदर्भ लाती है, जो आपको सूक्ष्मताओं को समझने और अधिक स्वाभाविक बोलने में मदद करती है।",
    "dashboard_talkus_about_title": "हम कौन हैं",
    "dashboard_talkus_email": "ईमेल",
    "dashboard_talkus_support_address": "पता",
    "dashboard_talkus_support_email": "तकनीकी सहायता",
    "dashboard_talkus_support_social": "सोशल मीडिया",
    "dashboard_talkus_title": "हमसे संपर्क करें",
    "dashboard_teacher_above_avg": "सामान्य औसत से ऊपर",
    "dashboard_teacher_average": "औसतन",
    "dashboard_teacher_below_avg": "सामान्य औसत से नीचे",
    "dashboard_teacher_dicts_title": "छात्र द्वारा पूर्ण किए गए श्रुतलेख",
    "dashboard_teacher_show_more": "और देखें",
    "dashboard_teacher_words_title": "छात्र द्वारा आत्मसात किए गए शब्द",
    "dashboard_teacher_wrong_subtitle": "घटनाएँ",
    "dashboard_teacher_wrong_title": "सबसे अधिक गलत लिखे गए शब्द - आपके छात्र",
    "dashboard_teacher_wrong_title_general": "सबसे अधिक गलत लिखे गए शब्द - सामान्य",
    "dict_a_newdict": "एक नया श्रुतलेख शुरू करें",
    "dict_by": "श्रुतलेख द्वारा: ",
    "dict_correct": "सुधारें",
    "dict_finish": "समाप्त करें",
    "dict_form_dict": "श्रुतलेख",
    "dict_form_dict_error": "पाठ दर्ज करें।",
    "dict_form_obs": "टिप्पणियाँ",
    "dict_form_ref": "संदर्भ (यदि कोई नहीं है तो 'N/A' दर्ज करें)",
    "dict_form_reference_error": "संदर्भ दर्ज करें।",
    "dict_form_select_error": "कम से कम एक छात्र का चयन करें।",
    "dict_next": "जारी रखें",
    "dict_reference": "संदर्भ:",
    "dict_reinforcement": "सुदृढीकरण श्रुतलेख",
    "dict_word_bellow": "नीचे दिए गए शब्दों का उपयोग करके पाठ को पूरा करें",
    "faq_title": "सामान्य प्रश्न - बार-बार पूछे जाने वाले प्रश्न",
    "footer_all_rights": "सभी अधिकार सुरक्षित हैं",
    "footer_support": "सहायता",
    "footer_talk_us": "हमसे संपर्क करें",
    "gallery_consent_modal_title": "उपयोग की शर्तें और अस्वीकरण",
    "gallery_info_title": "संपर्क जानकारी",
    "gallery_languages": "भाषाएँ",
    "gallery_score": "स्कोर",
    "galley_student_count": "मोबडिक्ट में छात्र",
    "galley_students_count": "मॉबडिक्ट पर छात्र",
    "helpicon_languages": "उन भाषाओं को चिह्नित करें जो आप सिखाते हैं",
    "helpicon_level": "भाषाओं के अध्ययन में दक्षता स्तर, भाषाओं के लिए सामान्य यूरोपीय संदर्भ रूपरेखा (सीईएफआर) के अनुसार",
    "helpicon_native_lang": "अनुवाद इस भाषा में होंगे।",
    "helpicon_score": "स्कोर इस शिक्षक द्वारा प्रति छात्र आत्मसात किए गए शब्दों की औसत संख्या का प्रतिनिधित्व करता है",
    "helpicon_subgender": "इन कहावतों को इन चयन के आधार पर बनाया जाएगा, जिससे वे अधिक रोचक और आपकी प्राथमिकताओं के अनुरूप हो जाएंगी।",
    "helpicon_teacher": "यदि आपके पास पहले से ही एक शिक्षक है, तो प्रदान किया गया कोड दर्ज करें या सीधे उनसे अनुरोध करें।",
    "helpicon_username": "यह जानकारी अन्य मोबडिक्ट उपयोगकर्ताओं के लिए दृश्य होगी।",
    "Infantojuvenil": "बाल और युवा",
    "landpage_banner_balloon": "एक नई भाषा को सुधारें!",
    "landpage_download_button": "डाउनलोड उपलब्ध है",
    "landpage_flags_title": "Mobdict® के साथ एक भाषा में सुधार करें",
    "landpage_free_action": "मैं इसे आज़माना चाहता हूँ",
    "landpage_free_text": "आप उस स्तर पर जिस भाषा का अध्ययन कर रहे हैं, उसका अभ्यास कर सकते हैं, Mobdict® को एक सप्ताह के लिए मुफ्त में आज़माएं और 5 तक श्रुतलेख करें ताकि प्रत्येक चुनौती के साथ आपका शब्दावली बढ़ सके।",
    "landpage_free_title": "एक निःशुल्क परीक्षण करें",
    "landpage_how_works_action": "मैं योजनाओं को जानना चाहता हूँ",
    "landpage_how_works_text": "हम भाषाओं और शिक्षा के प्रति जुनूनी हैं। हमारा उद्देश्य सभी के लिए सीखने को सुलभ और मजेदार बनाना है, जो एक नवाचारी दृष्टिकोण पर आधारित है।",
    "landpage_how_works_title": "हमारी सबसे बड़ी संतुष्टि एक नई भाषा सीखने में मदद करना है",
    "landpage_learn_action": "अभी शुरू करें",
    "landpage_learn_text": "हमारी शिक्षण विधि भाषा के अभ्यास पर केंद्रित है, जो कि सुनने की समझ, पढ़ने और लिखने का एक तकनीक है, जो आपकी भाषाई कौशल को प्राकृतिक और प्रभावी रूप से सुधारने में मदद करती है।",
    "landpage_learn_title": "और अधिक सीखें 😎",
    "landpage_more_text": "यह समझने के लिए हमारा वीडियो देखें कि कैसे डिक्टेशन का उपयोग करके भाषा सीखना आपके लिए लाभकारी हो सकता है।",
    "landpage_more_title": "सर्वश्रेष्ठ तरीके से सीखें  🎬",
    "landpage_write_text": "हाथ से लिखना शब्दावली की समझ और याददाश्त को बेहतर बना सकता है क्योंकि यह गहन संज्ञानात्मक प्रक्रियाओं को शामिल करता है। Mobdict Image Sender ऐप का उपयोग करके अपने हस्तलिखित नोट्स Mobdict® को भेजें।",
    "landpage_write_title": "हस्तलिखित लिखें और Mobdict® के साथ अधिक सीखें",
    "lg-acc": "साइन अप करें",
    "lg-acc-txt": "क्या आपके पास अभी तक खाता नहीं है? <a id=\"lg-acc\" href=\"/apl/register\">साइन अप करें</a>",
    "lg-auth-text": "<strong>Google Authenticator</strong> ऐप, या किसी अन्य प्रमाणीकरण ऐप के साथ, अपना टोकन जनरेट करने के लिए नीचे दिए गए QRCode को स्कैन करें",
    "lg-email": "ईमेल",
    "lg-error-pc": "सभी क्षेत्रों को भरें",
    "lg-error-pc-cloudflare": "सत्यापन की प्रतीक्षा करें या Cloudflare चेकबॉक्स को चेक करें।",
    "lg-error-pc-token": "क्षेत्र को भरें",
    "lg-fgp": "क्या आप अपना पासवर्ड भूल गए हैं?",
    "lg-fgpx": "क्या आप अपना पासवर्ड भूल गए?",
    "lg-google-auth": "प्रमाणीकरण ऐप में उपलब्ध कोड दर्ज करें \t",
    "lg-idioma": "भाषा",
    "lg-legend-login": "लॉगिन",
    "lg-legend-token": "अपना टोकन दर्ज करें",
    "lg-senha": "पासवर्ड",
    "lg-token": "टोकन",
    "login": "लॉगिन करें",
    "login_mfa": "अपनी पसंद का प्रमाणीकरण ऐप उपयोग करें और अपना टोकन उत्पन्न करने के लिए नीचे दिए गए QR कोड को स्कैन करें ",
    "login_mfa_qrcode": "नया QR कोड जेनरेट करें",
    "login-2": "प्रमाणीकरण किया जा रहा है...",
    "login-alt": "साथ प्रवेश करें",
    "login-signup": "कोई खाता नहीं है?",
    "notifications_modal_all_read": "सभी को पढ़ा हुआ चिह्नित करें",
    "notifications_modal_any": "आपके पास कोई सूचनाएं नहीं हैं।",
    "notifications_modal_button_1": "सभी",
    "notifications_modal_button_2": "अपठित",
    "notifications_modal_message": "नया श्रुतलेख उपलब्ध है!",
    "notifications_modal_time_more": "** दिन पहले",
    "notifications_modal_time_one": "1 दिन पहले",
    "notifications_modal_time_today": "आज",
    "notifications_modal_title": "सूचनाएं",
    "payment_promo_code": "प्रचार कोड",
    "payments_copy_paste": "Pix Copia e Cola",
    "payments_copy_paste_tooltip": "Copiado",
    "pix_error": "Erro ao gerar Qr Code Pix",
    "pix_success": "Cobrança Pix criada com sucesso",
    "plan_teacher_benefits_1": "व्यावहारिक अनुभव – अपने छात्रों के दृष्टिकोण से इस उपकरण का अन्वेषण करने के लिए 5 तक मुफ्त श्रुतलेख करें।",
    "plan_teacher_benefits_2": "प्रगति ट्रैकिंग – शिक्षक के रूप में अपनी प्रोफ़ाइल को मान्य करने के बाद, अपने सब्सक्राइब किए गए छात्रों के विकास को वास्तविक समय में ट्रैक करें।",
    "plan_teacher_benefits_3": "दृश्यता और अवसर – अपने सेवाओं और सोशल नेटवर्क को सीधे प्लेटफ़ॉर्म पर प्रचारित करें, छात्रों से जुड़ें जो पाठ्यक्रम या निजी पाठ की तलाश में हैं।",
    "plan_teacher_benefits_cta": "Mobdict से जुड़ें और एक शिक्षक के रूप में अपनी संभावनाओं का विस्तार करें!",
    "plan_teacher_benefits_text": "प्लेटफार्म पर पंजीकरण करने पर, आपको विशेष सुविधाओं तक पहुंच प्राप्त होगी ताकि आपके अनुभव को अनुकूलित किया जा सके और आपके छात्रों के सीखने की क्षमता को बढ़ाया जा सके।",
    "plan_teacher_benefits_title": "मॉबडिक्ट पर शिक्षकों के लिए लाभ",
    "plans_form_payments": "भुगतान विधि",
    "plans_payment_card": "क्रेडिट कार्ड",
    "register_validation_language": "कम से कम एक भाषा चुनें।",
    "register_validation_subgender": "जारी रखने के लिए कम से कम 3 उपश्रेणियां चुनें",
    "search_label_teachers": "एक शिक्षक खोजें",
    "search_placeholder": "खोजें...",
    "settings_saved": "परिवर्तन किए गए। उन्हें सहेजने के लिए 'जारी रखें' पर क्लिक करें।",
    "settings_teacher_warning": "चयनित शिक्षक इस भाषा को नहीं पढ़ाते। कृपया एक और कोड दर्ज करें (यदि आप बिना शिक्षक के जारी रखना चाहते हैं तो खाली छोड़ दें)।",
    "settings_teacher_warning_error": "शिक्षक या भाषा नहीं बदले गए।",
    "settings_teacher_warning_title": "अपने शिक्षक को बदलें",
    "students_dict": "वाचन कार्य पूरे किए गए",
    "students_known": "शब्द आत्मसात किए गए:",
    "students_label_green": "आत्मसात शब्द",
    "students_label_red": "आत्मसात नहीं हुआ शब्द",
    "students_languages": "अधिगम भाषाएँ:",
    "students_title": "मेरे छात्र",
    "students_wrong": "सबसे गलत शब्द:",
    "teacher_cv_title": "संक्षिप्त पाठ्यक्रम vitae प्रदान करें",
    "teacher_form_city": "शहर",
    "teacher_form_country": "देश",
    "teacher_form_cv": "पाठ्यक्रम vitae",
    "teacher_form_facebook": "Facebook",
    "teacher_form_instagram": "Instagram",
    "teacher_form_language": "पढ़ाए जाने वाले भाषाएँ",
    "teacher_form_linkedin": "LinkedIn",
    "teacher_form_photo": "फ़ोटो",
    "teacher_form_social": "सोशल मीडिया",
    "teacher_form_state": "संघीय इकाई",
    "teacher_form_youtube": "Youtube",
    "teacher_location_title": "राष्ट्रीयता",
    "teacher_pix_checkout_error": "आप शिक्षक के रूप में कोई योजना सदस्यता नहीं ले सकते।",
    "teacher_register_consent_error": "कम से कम एक संपर्क माध्यम (ईमेल या फोन) चुनें",
    "teacher_register_consent_location": "राष्ट्रीयता",
    "teacher_register_consent_term_error": "शर्त स्वीकार करें",
    "teacher_register_info_1": "आपकी प्रोफ़ाइल जानकारी सभी उपयोगकर्ताओं के लिए दृश्यमान होगी। चुनें कि आप क्या साझा करना चाहते हैं:",
    "teacher_register_info_2": "ध्यान दें: शिक्षक के रूप में प्रोफ़ाइल सक्रिय होने के बाद, आप प्लेटफ़ॉर्म पर डिक्टेशन नहीं कर पाएंगे, लेकिन आप अपने छात्रों के प्रदर्शन को ट्रैक कर पाएंगे।",
    "teacher_register_language_error": "कम से कम एक भाषा दर्ज करें",
    "teacher_register_languages": "भाषाएँ",
    "teacher_register_languages_subtitle": "जो भाषाएँ आप सिखाते हैं उन्हें चिह्नित करें",
    "teacher_register_photo_error": "छवि सहेजने में विफल",
    "teacher_register_photo_type_error": "अमान्य प्रारूप। कृपया png, jpeg या jpg का उपयोग करें",
    "teacher_students_subtitle": "कैप्शन",
    "teachers_title": "शिक्षकों की गैलरी",
    "title": "स्वागत",
    "val-token": "सत्यापित करें",
    "val-token-2": "सत्यापित किया जा रहा है...",
    "x26": "चुनने और जारी रखने से, आप हमारी सेवा की शर्तों, गोपनीयता नीति और एलजीपीडी से सहमत होते हैं",
    "x9": "चुनने और जारी रखने पर, आप हमारी सेवा की शर्तों और गोपनीयता नीति से सहमत होते हैं"
}