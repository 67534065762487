export const static_translation = {
    "bar_chart_general": "Général",
    "bar_chart_students": "Vos élèves",
    "dashboard_audio_player_ditado": "Suspendu",
    "dashboard_audio_player_original": "Original",
    "dashboard_become_teacher": "Activer le Profil Professeur",
    "dashboard_create_new_password_check1_text": "Lettre majuscule",
    "dashboard_create_new_password_check2_text": "Nombre",
    "dashboard_create_new_password_check3_text": "Au moins 6 caractères",
    "dashboard_create_new_password_check4_text": "Caractère spécial",
    "dashboard_create_new_password_step1_text": "Entrez un mot de passe",
    "dashboard_create_new_password_step2_text": "Répétez le mot de passe",
    "dashboard_createpassword_step1_text": "Entrez un nouveau mot de passe pour votre compte",
    "dashboard_createpassword_step1_title": "Nouveau mot de passe",
    "dashboard_createpassword_step2_text": "Vous pouvez maintenant vous connecter avec votre nouveau mot de passe",
    "dashboard_createpassword_step2_title": "Nouveau mot de passe créé avec succès",
    "dashboard_dict_added_increase": "Vous avez ajouté plus",
    "dashboard_dict_added_words": "mots à votre vocabulaire.",
    "dashboard_dict_added_words_singular": "mot à votre vocabulaire.",
    "dashboard_dict_back_homepage": "Retourner à la page d'accueil",
    "dashboard_dict_congrats": "Félicitations!",
    "dashboard_dict_obs": "Observations",
    "dashboard_dict_restart": "Commencer une nouvelle dictée",
    "dashboard_dict_see_obs": "Voir les observations",
    "dashboard_dict_see_translate": "Voir la traduction",
    "dashboard_dict_translate": "Traduction",
    "dashboard_dict_writing_handed": "Écriture manuscrite",
    "dashboard_dict_writing_meta": "Objectif",
    "dashboard_dict_writing_progress": "Votre progression",
    "dashboard_dict_writing_special": "Caractères spéciaux",
    "dashboard_dict_writing_step_1": "1. Téléchargez l'application Mobdict Image Sender sur l'App Store ou Play Store et connectez-vous.",
    "dashboard_dict_writing_step_1_web": "1. Scannez le code QR à côté. Vous serez redirigé vers le site Mobdict Image Sender.",
    "dashboard_dict_writing_step_2": "2. Écrivez la dictée que vous avez entendue sur un papier et prenez une photo.",
    "dashboard_dict_writing_step_2_web": "2. Écrivez à la main la dictée que vous avez entendue et prenez une photo.",
    "dashboard_dict_writing_step_3": "3. Envoyez l'image via l'application et cliquez sur le bouton à côté pour terminer.",
    "dashboard_dict_writing_step_3_web": "3. Envoyez l'image via le site et cliquez sur le bouton à côté pour terminer.",
    "dashboard_dict_writing_step_action": "Déjà envoyé",
    "dashboard_dict_writing_step_confirm": "J'ai vérifié, Corriger",
    "dashboard_dict_writing_title": "Tapez le texte",
    "dashboard_dict_writing_words": "mots",
    "dashboard_dict_writing_words_singular": "mot",
    "dashboard_dict_wrong_challange": "Défi",
    "dashboard_dict_wrong_finish": "Terminer",
    "dashboard_dict_wrong_omit": "mots omis",
    "dashboard_dict_wrong_omit_singular": "mot omis",
    "dashboard_dict_wrong_reinforcement": "Faire une dictée de renforcement",
    "dashboard_dict_wrong_words": " mots sont incorrects",
    "dashboard_dict_wrong_words_singular": "mot est incorrect",
    "dashboard_forgot_step1_text": "Entrez votre e-mail enregistré pour récupérer votre mot de passe",
    "dashboard_forgot_step1_title": "Mot de passe oublié ?",
    "dashboard_forgot_step1_title_afirm": "J'ai oublié mon mot de passe",
    "dashboard_forgot_step2_text": "Accédez au lien de récupération qui a été envoyé à votre email enregistré",
    "dashboard_forgot_step2_title": "Vérifiez votre email",
    "dashboard_form_account_title": "Mon compte",
    "dashboard_form_back": "Retour",
    "dashboard_form_balance_title": "Contrôle des crédits",
    "dashboard_form_cancel": "Annuler",
    "dashboard_form_confirm_password": "Répétez le mot de passe",
    "dashboard_form_delete_checkbox": "Suppression de compte",
    "dashboard_form_delete_confirmar": "Confirmer",
    "dashboard_form_delete_msg": "Après confirmation, votre demande sera transmise à notre équipe. La suppression du compte et des données sera effectuée dans un délai de 72 heures de manière définitive et irréversible. Une fois le processus terminé, vous recevrez un e-mail de confirmation. En cliquant sur 'Continuer', vous acceptez ces termes.",
    "dashboard_form_email": "Email",
    "dashboard_form_level": "Niveau",
    "dashboard_form_name": "Nom complet",
    "dashboard_form_next": "Continuer",
    "dashboard_form_nickname": "Choisissez votre pseudo",
    "dashboard_form_password": "Mot de passe",
    "dashboard_form_password_title": "Changer le mot de passe",
    "dashboard_form_password_validation": "Le nouveau mot de passe et la confirmation du nouveau mot de passe ne sont pas identiques",
    "dashboard_form_phone": "Portable",
    "dashboard_form_save": "Sauvegarder",
    "dashboard_form_subgender": "Sélectionnez les sous-genres littéraires",
    "dashboard_form_teacher": "Code Professeur",
    "dashboard_home_cta": "Commençons la dictée",
    "dashboard_home_dicts_days": "jours restants",
    "dashboard_home_dicts_dict": "Dictées",
    "dashboard_home_dicts_done": "réalisés",
    "dashboard_home_dicts_plan": "Dictées contractées",
    "dashboard_home_dicts_test": "Période D'essai",
    "dashboard_home_dicts_test_done": "Période d'essai terminée",
    "dashboard_home_learning": "J'apprends",
    "dashboard_home_level": "Niveau",
    "dashboard_home_ranking_name": "Nom",
    "dashboard_home_ranking_points": "Score",
    "dashboard_home_ranking_position": "Position",
    "dashboard_home_ranking_see_more": "Voir plus",
    "dashboard_home_ranking_title": "Classement",
    "dashboard_home_see_plans": "Voir les plans",
    "dashboard_home_teacher": "Professeur",
    "dashboard_no_plans": "Plans disponibles bientôt.",
    "dashboard_password_title": "Créez votre mot de passe",
    "dashboard_plan_action": "S'abonner",
    "dashboard_plan_benefits": "Voir les avantages",
    "dashboard_plan_currency": "R$",
    "dashboard_plan_dict": "dictées",
    "dashboard_plan_free_action": "Tester",
    "dashboard_plan_name": "Gratuit",
    "dashboard_plan_pop": "Le plus populaire",
    "dashboard_plan_subtitle": "facturés annuellement",
    "dashboard_plan_title": "Abonnez-vous à l'un de nos plans",
    "dashboard_qtdade_TD": "essai gratuit",
    "dashboard_ranking_empty": "Aucune information trouvée",
    "dashboard_register_and": "et",
    "dashboard_register_consent_term_1": "J'autorise le partage des éléments sélectionnés avec d'autres utilisateurs de la plateforme.",
    "dashboard_register_gallery_term_1": "Je souhaite apparaître dans la galerie des professeurs de langues.",
    "dashboard_register_level": "Sélectionnez la langue et le niveau",
    "dashboard_register_step0_student": "Élève",
    "dashboard_register_step0_teacher": "Professeur",
    "dashboard_register_step0_text": "Indique dans quelle catégorie vous souhaitez vous inscrire",
    "dashboard_register_step0_title": "Définissez Votre Profil",
    "dashboard_register_step1_action": "Continuer",
    "dashboard_register_step1_text": "Fournissez vos informations d'inscription",
    "dashboard_register_step1_title": "Saisissez vos données",
    "dashboard_register_step2_action": "Fermer",
    "dashboard_register_step2_back": "Retour",
    "dashboard_register_step2_text": "Merci pour votre inscription.",
    "dashboard_register_step2_title": "Inscription terminée avec succès",
    "dashboard_register_subgender": "Sélectionnez les sous-genres littéraires",
    "dashboard_register_subtitle": "Saisissez vos informations d'enregistrement",
    "dashboard_register_successfull": "Compte créé avec succès",
    "dashboard_register_teacher_code": "Identifiez votre professeur",
    "dashboard_register_teacher_step1_text": "Indiquez vos réseaux sociaux et une photo de profil",
    "dashboard_register_teacher_step1_title": "Réseaux Sociaux",
    "dashboard_register_terms_1": "En sélectionnant et en continuant, vous acceptez nos",
    "dashboard_register_terms_2": "Conditions d'utilisation",
    "dashboard_register_terms_3": "Politique de confidentialité",
    "dashboard_register_title": "Entrez vos données",
    "dashboard_register_validation_accept_terms": "Acceptez les termes",
    "dashboard_register_validation_email": "Entrez l'email",
    "dashboard_register_validation_not_match": "Le nouveau mot de passe et la confirmation ne correspondent pas",
    "dashboard_register_validation_password": "Entrez le nouveau mot de passe",
    "dashboard_register_validation_password_confirmation": "Entrez la confirmation du nouveau mot de passe",
    "dashboard_send_dict_all": "Tous",
    "dashboard_send_dict_students_clean": "Effacer",
    "dashboard_send_dict_students_subtitle": "Choisissez les élèves à qui vous souhaitez envoyer cette dictée",
    "dashboard_send_dict_students_title": "Sélectionner des élèves",
    "dashboard_send_dict_title": "Écrire votre dictée",
    "dashboard_settings_2fa": "Activer l'authentification à deux facteurs",
    "dashboard_settings_active": "Actif dans la galerie",
    "dashboard_settings_adjust_pause": "Ajuster la pause de dictée",
    "dashboard_settings_consent": "Réinitialiser les permissions",
    "dashboard_settings_consent_title": "Sélectionnez les autorisations.",
    "dashboard_settings_default_lang": "Langue par défaut",
    "dashboard_settings_define": "Définir",
    "dashboard_settings_languages": "Réinitialiser les langues d'enseignement",
    "dashboard_settings_learn_lang": "Langue d'apprentissage",
    "dashboard_settings_native_lang": "Langue maternelle",
    "dashboard_settings_pause": "Pause",
    "dashboard_settings_recurrency": "Abonnement récurrent",
    "dashboard_settings_subgender": "Réinitialiser les sous-genres littéraires",
    "dashboard_settings_title": "Paramètres",
    "dashboard_side_account": "Mon compte",
    "dashboard_side_balance": "Bilan",
    "dashboard_side_buy": "Ajouter des Dictées",
    "dashboard_side_dict": "Publier la dictée",
    "dashboard_side_exit": "Déconnexion",
    "dashboard_side_faq": "FAQ",
    "dashboard_side_home": "Accueil",
    "dashboard_side_ranking": "Classement",
    "dashboard_side_settings": "Paramètres",
    "dashboard_side_students": "Mes élèves",
    "dashboard_side_talk_us": "Nous contacter",
    "dashboard_side_teachers": "Galerie des Professeurs",
    "dashboard_side_tutorial": "Tutoriels",
    "dashboard_subtitle_subgender": "Minimum 3",
    "dashboard_table_balance_1": "Dictées Contractées",
    "dashboard_table_balance_2": "Dictées Générées Et Non Réalisées",
    "dashboard_table_balance_3": "Dictées Réalisées",
    "dashboard_table_balance_4": "Dictées Disponibles",
    "dashboard_table_balance_desc": "Description",
    "dashboard_table_balance_qtd": "Quantité",
    "dashboard_talkus_about_text": "Nous sommes passionnés par les langues et l'éducation. Notre objectif est de rendre l'apprentissage accessible et amusant pour tous, en utilisant une approche innovante basée sur des dictées. Dans Mobdict®, transformer votre apprentissage des langues est plus facile et amusant avec des dictées! Ces petites perles de sagesse offrent une façon unique d'absorber la culture et la langue. En apprenant une nouvelle langue, découvrez des dictées qui reflètent la façon de penser et de vivre des locuteurs natifs. Chaque expression apporte une leçon précieuse et un contexte culturel, vous aidant à maîtriser les nuances et à paraître plus naturel.",
    "dashboard_talkus_about_title": "Qui sommes-nous",
    "dashboard_talkus_email": "Email",
    "dashboard_talkus_support_address": "Adresse",
    "dashboard_talkus_support_email": "Support technique",
    "dashboard_talkus_support_social": "Réseaux sociaux",
    "dashboard_talkus_title": "Nous contacter",
    "dashboard_teacher_above_avg": "au-dessus de la moyenne générale",
    "dashboard_teacher_average": "en moyenne",
    "dashboard_teacher_below_avg": "en dessous de la moyenne générale",
    "dashboard_teacher_dicts_title": "Dictées Réalisées par Élève",
    "dashboard_teacher_show_more": "Voir plus",
    "dashboard_teacher_words_title": "Mots Assimilés par Élève",
    "dashboard_teacher_wrong_subtitle": "Occurrences",
    "dashboard_teacher_wrong_title": "Mots les plus Mal Orthographiés - Vos élèves",
    "dashboard_teacher_wrong_title_general": "Mots les plus Mal Orthographiés - Général",
    "dict_a_newdict": "Commencer une nouvelle dictée",
    "dict_by": "Dicté par: ",
    "dict_correct": "Corriger",
    "dict_finish": "Terminer",
    "dict_form_dict": "Dictée",
    "dict_form_dict_error": "Insérez le texte.",
    "dict_form_obs": "Observations",
    "dict_form_ref": "Référence (entrez 'N/A' si aucune)",
    "dict_form_reference_error": "Insérez la référence.",
    "dict_form_select_error": "Sélectionnez au moins un élève.",
    "dict_next": "Continuer",
    "dict_reference": "Référence:",
    "dict_reinforcement": "Dictée de renforcement",
    "dict_word_bellow": "Utilisez les mots ci-dessous pour compléter le texte",
    "faq_title": "FAQ - Questions fréquentes",
    "footer_all_rights": "Tous droits réservés",
    "footer_support": "Soutien",
    "footer_talk_us": "Contactez-nous",
    "gallery_consent_modal_title": "Conditions d'utilisation et Décharge de Responsabilité",
    "gallery_info_title": "Informations de Contact",
    "gallery_languages": "Langues",
    "gallery_score": "Score",
    "galley_student_count": "étudiant dans Mobdict",
    "galley_students_count": "élèves sur Mobdict",
    "helpicon_languages": "Cochez les langues que vous enseignez",
    "helpicon_level": "Niveaux de compétence dans l'apprentissage des langues, selon le Cadre Européen Commun de Référence pour les Langues (CECR)",
    "helpicon_native_lang": "Les traductions seront dans cette langue.",
    "helpicon_score": "Le score représente la moyenne des mots assimilés par élève pour cet enseignant",
    "helpicon_subgender": "Les dictons seront créés en fonction de ces choix, les rendant plus intéressants et alignés avec vos préférences.",
    "helpicon_teacher": "Si vous avez déjà un professeur, entrez le code fourni ou demandez-le directement à celui-ci.",
    "helpicon_username": "Cette information sera visible pour les autres utilisateurs de Mobdict.",
    "Infantojuvenil": "Enfants et adolescents",
    "landpage_banner_balloon": "Améliorez une nouvelle langue!",
    "landpage_download_button": "Téléchargement disponible sur",
    "landpage_flags_title": "Améliorez une langue avec Mobdict®",
    "landpage_free_action": "Je veux essayer",
    "landpage_free_text": "Vous pouvez pratiquer la langue que vous étudiez, à votre niveau, en testant Mobdict® gratuitement pendant une semaine et faire jusqu'à 5 dictées pour voir votre vocabulaire augmenter à chaque défi.",
    "landpage_free_title": "Faites un essai gratuit",
    "landpage_how_works_action": "Je veux connaître les plans",
    "landpage_how_works_text": "Nous sommes passionnés par les langues et l'éducation. Notre objectif est de rendre l'apprentissage accessible et amusant pour tous, en utilisant une approche innovante basée sur des dictées.",
    "landpage_how_works_title": "Notre plus grande satisfaction est d'aider à l'apprentissage d'une nouvelle langue",
    "landpage_learn_action": "Commencer maintenant",
    "landpage_learn_text": "Notre méthode d'enseignement se concentre sur la pratique des langues par le biais de dictées, une technique qui combine compréhension auditive, lecture et pratique écrite pour améliorer vos compétences linguistiques de manière naturelle et efficace.",
    "landpage_learn_title": "Apprenez beaucoup plus 😎",
    "landpage_more_text": "Regardez notre vidéo pour comprendre comment l'apprentissage des langues à travers les dictées peut vous être bénéfique.",
    "landpage_more_title": "Apprenez de la meilleure manière 🎬",
    "landpage_write_text": "Écrire à la main peut améliorer la rétention et la compréhension du vocabulaire car cela implique des processus cognitifs plus profonds. Envoyez vos manuscrits à Mobdict® en utilisant l'application Mobdict Image Sender.",
    "landpage_write_title": "Écrivez à la main et apprenez plus avec Mobdict®",
    "lg-acc": "Inscrivez-vous",
    "lg-acc-txt": "Vous n'avez pas encore de compte? <a id=\"lg-acc\" href=\"/apl/register\">Inscrivez-vous</a>",
    "lg-auth-text": "Avec l'application <strong>Google Authenticator</strong> ou une autre application d'authentification, scannez le QRCode ci-dessous pour générer votre token",
    "lg-email": "Email",
    "lg-error-pc": "Remplissez tous les champs",
    "lg-error-pc-cloudflare": "Attendez la vérification ou cochez la case Cloudflare.",
    "lg-error-pc-token": "Remplissez le champ",
    "lg-fgp": "Vous avez oublié votre mot de passe?",
    "lg-fgpx": "Mot de passe oublié?",
    "lg-google-auth": "Entrez le code disponible dans l'application d'authentification",
    "lg-idioma": "Langue",
    "lg-legend-login": "Login",
    "lg-legend-token": "Entrez votre jeton",
    "lg-senha": "Mot de passe",
    "lg-token": "Jeton",
    "login": "Connexion",
    "login_mfa": "Utilisez l'application d'authentification de votre choix et scannez le code QR ci-dessous pour générer votre jeton",
    "login_mfa_qrcode": "Générer un nouveau QR Code",
    "login-2": "Authentification en cours...",
    "login-alt": "Entrer avec",
    "login-signup": "Vous n'avez pas de compte ?",
    "notifications_modal_all_read": "Marquer tout comme lu",
    "notifications_modal_any": "Vous n'avez aucune notification.",
    "notifications_modal_button_1": "Toutes",
    "notifications_modal_button_2": "Non lues",
    "notifications_modal_message": "Nouvelle dictée disponible !",
    "notifications_modal_time_more": "Il y a ** jours",
    "notifications_modal_time_one": "Il y a 1 jour",
    "notifications_modal_time_today": "Aujourd'hui",
    "notifications_modal_title": "Notifications",
    "payment_promo_code": "Code promotionnel",
    "payments_copy_paste": "Pix Copia e Cola",
    "payments_copy_paste_tooltip": "Copiado",
    "pix_error": "Erro ao gerar Qr Code Pix",
    "pix_success": "Cobrança Pix criada com sucesso",
    "plan_teacher_benefits_1": "Expérience pratique – Réalisez jusqu'à 5 dictées gratuites pour explorer l'outil du point de vue de vos élèves.",
    "plan_teacher_benefits_2": "Suivi des progrès – Après la validation de votre profil en tant qu'enseignant, suivez le développement de vos élèves abonnés en temps réel.",
    "plan_teacher_benefits_3": "Visibilité et opportunités – Faites la promotion de vos services et réseaux sociaux directement sur la plateforme, en vous connectant avec des élèves recherchant des cours ou des leçons particulières.",
    "plan_teacher_benefits_cta": "Rejoignez Mobdict et élargissez vos possibilités en tant qu'éducateur !",
    "plan_teacher_benefits_text": "En vous inscrivant sur la plateforme, vous aurez accès à des fonctionnalités exclusives pour optimiser votre expérience et améliorer l'apprentissage de vos élèves.",
    "plan_teacher_benefits_title": "Avantages pour les enseignants sur Mobdict",
    "plans_form_payments": "Mode de paiement",
    "plans_payment_card": "Carte de Crédit",
    "register_validation_language": "Sélectionnez au moins une langue.",
    "register_validation_subgender": "Sélectionnez au moins 3 sous-genres pour continuer",
    "search_label_teachers": "Chercher un professeur",
    "search_placeholder": "Chercher...",
    "settings_saved": "Modifications effectuées. Cliquez sur 'Continuer' pour les enregistrer.",
    "settings_teacher_warning": "L'enseignant sélectionné n'enseigne pas cette langue. Veuillez insérer un autre code (laissez vide si vous souhaitez continuer sans enseignant).",
    "settings_teacher_warning_error": "Enseignant ou langue non modifiés.",
    "settings_teacher_warning_title": "Modifiez votre enseignant",
    "students_dict": "Dictées réalisées",
    "students_known": "Mots assimilés:",
    "students_label_green": "Mot assimilé",
    "students_label_red": "Mot non assimilé",
    "students_languages": "Langues d'apprentissage:",
    "students_title": "Mes élèves",
    "students_wrong": "Mots les plus erronés :",
    "teacher_cv_title": "Fournissez un bref CV",
    "teacher_form_city": "Ville",
    "teacher_form_country": "Pays",
    "teacher_form_cv": "CV",
    "teacher_form_facebook": "Facebook",
    "teacher_form_instagram": "Instagram",
    "teacher_form_language": "Langues enseignées",
    "teacher_form_linkedin": "LinkedIn",
    "teacher_form_photo": "Photo",
    "teacher_form_social": "Réseaux sociaux",
    "teacher_form_state": "Unité fédérative",
    "teacher_form_youtube": "Youtube",
    "teacher_location_title": "Nationalité",
    "teacher_pix_checkout_error": "Vous ne pouvez pas souscrire à un plan en tant qu’Enseignant.",
    "teacher_register_consent_error": "Sélectionnez au moins un moyen de contact (email ou téléphone)",
    "teacher_register_consent_location": "Nationalité",
    "teacher_register_consent_term_error": "Acceptez le terme",
    "teacher_register_info_1": "Les informations de votre profil seront visibles par tous les utilisateurs. Choisissez celles que vous souhaitez partager :",
    "teacher_register_info_2": "ATTENTION : Après l'activation de votre profil en tant qu'Enseignant, vous ne pourrez plus effectuer de dictées sur la plateforme, mais vous pourrez suivre les performances de vos élèves.",
    "teacher_register_language_error": "Entrez au moins une langue",
    "teacher_register_languages": "Langues",
    "teacher_register_languages_subtitle": "Marquez les langues que vous enseignez",
    "teacher_register_photo_error": "Échec de l'enregistrement de l'image",
    "teacher_register_photo_type_error": "Format invalide. Utilisez png, jpeg ou jpg",
    "teacher_students_subtitle": "Légende",
    "teachers_title": "Galerie des Professeurs",
    "title": "Bienvenue",
    "val-token": "Valider",
    "val-token-2": "Validation en cours...",
    "x26": "En sélectionnant et en continuant, vous acceptez nos Conditions de service, Politique de confidentialité et LGPD",
    "x9": "En sélectionnant et en continuant, vous acceptez nos Conditions de service et notre Politique de confidentialité"
}